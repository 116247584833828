<template>
  <div id="service_predictor">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-3 border-end p-0 overflow-y-auto"
          ref="sidebarContainer"
        >
          <div class="col-md-12">
            <div class="p-3 border-bottom">
              <div class="title">Service Availability Predictor</div>
              <div class="form-group">
                <label class="sub-title pb-2">Operator API Key</label>
                <input
                  type="password"
                  name="operatorKey"
                  class="form-control"
                  v-model="operatorKey"
                  :class="operatorKeyError ? 'border-danger' : ''"
                  @change="findOperators"
                />
                <div class="text-danger" v-if="keyResponse">
                  {{ keyResponse }}
                </div>
              </div>
              <div class="col-md-12 mt-2 mb-1">
                <button
                  type="button"
                  class="btn btn-predictor"
                  @click="checkAPIKeyHealth"
                >
                  Authenticate
                </button>
                <button
                  type="button"
                  class="btn btn-predictor ms-2"
                  @click="clearInputs"
                  v-if="keyResponse"
                >
                  <i class="fa fa-times"></i> Clear
                </button>
              </div>

              <div class="form-group">
                <label class="sub-title pb-2">Operator</label>
                <input
                  type="text"
                  name="operator"
                  class="form-control"
                  v-model="operator"
                  :disabled="operatorDisabled"
                  :class="operatorError ? 'border-danger' : ''"
                  @change="checkOperatorExist"
                />
              </div>
              <div class="col-md-12 mt-2 mb-1 text-danger" v-if="operatorError">
                Operator entered is invalid.
                <a
                  href="#"
                  @click="showOperatorModal = true"
                  class="text-indigo"
                  >Click here</a
                >
                to select an operator.
              </div>
            </div>
            <div class="p-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="sub-title pb-2"
                      >Base Node<span class="base">●</span>
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        name="baseNodeRadioOptions"
                        class="form-check-input"
                        v-model="findNearest"
                        value="true"
                        id="findNearest"
                        @click="inputDisable(true)"
                      /><label class="form-check-label" for="findNearest"
                        >Find Nearest</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        name="baseNodeRadioOptions"
                        class="form-check-input"
                        v-model="useSpecific"
                        value="true"
                        id="useSpecific"
                        @click="inputDisable(false)"
                      />
                      <label class="form-check-label" for="useSpecific"
                        >Use Specific</label
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="serial_number">Serial Number</label>
                    <input
                      type="text"
                      name="serial_number"
                      class="form-control"
                      v-model="serial"
                      :disabled="disabled"
                      :class="serialError ? 'border-danger' : ''"
                    />
                  </div>
                  <div class="form-group">
                    <label for="location">Location</label>
                    <input
                      type="text"
                      name="location"
                      class="form-control"
                      v-model="bn_location"
                      :class="{
                        'border-danger': !isValidCoordinates(bn_location),
                      }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="azimuth">Azimuth</label>
                    <input
                      type="text"
                      name="azimuth"
                      class="form-control"
                      v-model="azimuth"
                      :class="{ 'border-danger': !isValidNumber(azimuth) }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="downtilt">Tilt</label>
                    <input
                      type="text"
                      name="downtilt"
                      class="form-control"
                      v-model="downtilt"
                      :class="{ 'border-danger': !isValidNumber(downtilt) }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height AGL (m)</label>
                    <input
                      type="text"
                      name="height"
                      class="form-control"
                      v-model="height_agl"
                      :class="{ 'border-danger': !isValidNumber(height_agl) }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height GPS (m)</label>
                    <input
                      type="text"
                      name="height"
                      class="form-control"
                      v-model="height_gps"
                      :class="{ 'border-danger': !isValidNumber(height_gps) }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="sub-title pe-1 pb-2"
                      >Remote Node<span class="remote">●</span>
                    </label>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        name="remoteNodeRadioOptions"
                        class="form-check-input"
                        v-model="findByAddress"
                        value="true"
                        id="findByAddress"
                        @click="remoteChange(true)"
                      /><label class="form-check-label" for="findByAddress"
                        >Address</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        type="radio"
                        name="remoteNodeRadioOptions"
                        class="form-check-input"
                        v-model="findBylatlong"
                        value="true"
                        id="findBylatlong"
                        @click="remoteChange(false)"
                      />
                      <label class="form-check-label" for="findBylatlong"
                        >Lat / Long</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="rnAddressShow">
                  <div class="form-group input-group">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="rn_address"
                      :class="addressError ? 'border-danger' : ''"
                      @change="findOperators"
                    />
                    <span class="input-group-text"
                      ><i class="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div v-if="addressErrorMsg" class="text-danger">
                    {{ addressErrorMsg }}
                  </div>
                  <div class="form-group">
                    <label for="location">Location</label>
                    <input
                      type="text"
                      name="location"
                      class="form-control"
                      v-model="rn_location"
                      :class="{
                        'border-danger': !isValidCoordinates(rn_location),
                      }"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="col-md-12" v-else>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="address">Latitude</label>
                        <input
                          type="text"
                          name="latitude"
                          class="form-control"
                          v-model="rn_latitude"
                          :class="{
                            'border-danger': !isValidCoordinates(
                              rn_latitude,
                              'latitude'
                            ),
                          }"
                          @change="findAddress"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="longitude">Longitude</label>
                        <input
                          type="text"
                          name="location"
                          class="form-control"
                          v-model="rn_longitude"
                          :class="{
                            'border-danger': !isValidCoordinates(
                              rn_longitude,
                              'longitude'
                            ),
                          }"
                          @change="findAddress"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height - Above Open (m)</label>
                    <input
                      type="text"
                      name="above_open"
                      class="form-control"
                      v-model="rn_above_open"
                      :class="{
                        'border-danger': !isValidNumber(rn_above_open),
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height - Above Roof (m)</label>
                    <input
                      type="text"
                      name="above_roof"
                      class="form-control"
                      v-model="rn_above_roof"
                      :class="{
                        'border-danger': !isValidNumber(rn_above_roof),
                      }"
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-2 mb-1">
                  <button
                    type="button"
                    class="btn btn-predictor me-2"
                    @click="calculateLost"
                  >
                    Calculate Path Loss & Speed
                  </button>
                  <button
                    type="button"
                    class="btn btn-predictor btn-modal"
                    v-if="successful && !showInfoDiv"
                    @click="toggleModal"
                  >
                    Show Modal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 p-0">
          <div class="container-fluid">
            <div class="row no-gutters">
              <div class="col-md-12 p-0 position-relative">
                <google-map
                  :remote-address="rn_address"
                  :base-node="baseNode"
                  :remote-node="remoteNode"
                  @update-lat-lng="handleUpdateLatLng"
                />
                <!-- Overlay Modal -->
                <div class="overlay" v-if="successful && showInfoDiv">
                  <div class="overlay-content">
                    <div class="card">
                      <div class="card-body">
                        <div class="close" @click="toggleModal">&times;</div>
                        <div class="row mt-3">
                          <div class="col-md-12" v-if="apiResponse">
                            <div class="text-danger text-center">
                              API Response: {{ apiResponse }}<br />Please try
                              again using another address.
                            </div>
                          </div>
                          <div class="col-md-12 img-enlarge" v-if="profileSvg">
                            <div class="card-text text-center pt-2">
                              Link Diagram
                            </div>
                            <img
                              :src="profileSvg"
                              class="svg-container"
                              id="profile-svg-container"
                            />
                            <button
                              class="enlarge-button"
                              @click="enlargeImage('line')"
                            >
                              <i class="fa fa-expand"></i> Enlarge Image
                            </button>
                          </div>
                          <div class="col-md-12 graph-bg">
                            <div class="row">
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="pathLossData"
                              >
                                <img
                                  v-if="pathLossSvg"
                                  :src="pathLossSvg"
                                  class="svg-container"
                                  id="path-loss-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('path-loss')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="linkSpeedData"
                              >
                                <img
                                  v-if="linkSpeedSvg"
                                  :src="linkSpeedSvg"
                                  class="svg-container"
                                  id="link-speed-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('link-speed')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="dialog"
      id="svg-popup"
      v-if="dialogVisible"
      @click.self="closeDialog"
    >
      <div
        class="dialog-content"
        :class="svgType === 'line' ? 'diagram-width' : 'graph-width'"
        @click.stop
      >
        <button @click="closeDialog" class="close-icon">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <div class="row">
          <div class="col-md-12">
            <img
              :src="svgContent"
              class="dialog-svg-container"
              id="svg-container"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="showOperatorModal ? 'd-block' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Operator List</h5>
          <button
            type="button"
            class="btn-close"
            @click="showOperatorModal = false"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="operatorNames.length > 0">
            <p>Select an operator from the list below:</p>
            <ul class="list-group">
              <li
                v-for="(operator, index) in operatorNames"
                :key="index"
                class="list-group-item fw-bold"
              >
                {{ operator }}
                <div class="float-end">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="selectOperator(operator)"
                  >
                    Select Operator
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No operators found at this address.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="showOperatorModal = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMap from "./GoogleMap.vue";
export default {
  components: {
    "google-map": GoogleMap,
  },
  data() {
    return {
      successful: false,
      operatorKey: "",
      operator: "",
      keyResponse: "",
      serial: "",
      bn_location: "",
      azimuth: "",
      height_agl: "",
      height_gps: "",
      downtilt: "",
      findNearest: true,
      useSpecific: false,
      findByAddress: true,
      findBylatlong: false,
      disabled: true,
      rnAddressShow: true,
      operatorDisabled: false,
      showOperatorModal: false,
      rn_latitude: "",
      rn_longitude: "",
      rn_address: "",
      rn_location: "",
      rn_above_open: "",
      rn_above_roof: "",
      serialError: false,
      addressError: false,
      operatorError: false,
      operatorKeyError: false,
      addressErrorMsg: "",
      baseNode: { lat: "", lng: "" },
      remoteNode: { lat: "", lng: "" },
      svgContent: "",
      profileSvg: "",
      pathLossData: "",
      linkSpeedData: "",
      pathLossSvg: "",
      linkSpeedSvg: "",
      showInfoDiv: false,
      dialogVisible: false,
      svgType: "",
      apiResponse: "",
      addresErrorMsg: "",
      operatorNames: [],
    };
  },
  mounted() {
    const rowElement = this.$refs.sidebarContainer;
    if (rowElement && window.innerWidth > 767) {
      rowElement.style.height = window.innerHeight - 40 + "px";
    }
    window.addEventListener("resize", this.toggleModal);
  },
  watch: {
    profileSvg() {
      this.makeSVGResponsive("profile-svg-container");
    },
    pathLossSvg() {
      this.makeSVGResponsive("path-loss-svg-container");
    },
    linkSpeedSvg() {
      this.makeSVGResponsive("link-speed-svg-container");
    },
  },
  methods: {
    inputDisable(val) {
      this.disabled = val;
      this.serialError = false;
      this.addressError = false;
      this.operatorKeyError = false;
      this.clearInputs();
    },
    remoteChange(val) {
      this.rnAddressShow = val;
      this.addressError = false;
      this.operatorKeyError = false;
    },
    clearInputs() {
      this.serial = "";
      this.bn_location = "";
      this.azimuth = "";
      this.height_agl = "";
      this.height_gps = "";
      this.downtilt = "";
      this.rn_address = "";
      this.rn_location = "";
      this.rn_above_open = "";
      this.rn_above_roof = "";
      this.operator = "";
      this.keyResponse = "";
      this.apiResponse = "";
      this.addressErrorMsg = "";
    },
    enlargeImage(diagram) {
      this.dialogVisible = true;
      if (diagram == "line") {
        this.svgContent = this.profileSvg;
        this.svgType = "line";
      }
      if (diagram == "path-loss") {
        this.svgContent = this.pathLossSvg;
        this.svgType = "graph";
      }
      if (diagram == "link-speed") {
        this.svgContent = this.linkSpeedSvg;
        this.svgType = "graph";
      }
      // Call makeSVGResponsive after SVG content is updated
      this.$nextTick(() => {
        this.makeDialogSVGResponsive();
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    toggleModal() {
      this.showInfoDiv = !this.showInfoDiv;
      if (this.showInfoDiv) {
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("profile-svg-container");
          this.makeSVGResponsive("path-loss-svg-container");
          this.makeSVGResponsive("link-speed-svg-container");
        });
      }
    },
    isValidNumber(value) {
      if (value != "") {
        // Regular expression to match numbers with optional decimal point
        const numberRegex = /^-?\d+(\.\d+)?$/;

        // Check if the input value matches the regular expression
        let numberCheck = numberRegex.test(value);
        return numberCheck;
      }
      return true;
    },
    isValidCoordinates(value, type = "") {
      if (value != "" && type == "") {
        const parts = value.split(",");
        if (parts.length !== 2) {
          return false;
        }
        const lat = parseFloat(parts[0]);
        const lon = parseFloat(parts[1]);
        let checkLocation =
          !isNaN(lat) &&
          !isNaN(lon) &&
          lat >= -90 &&
          lat <= 90 &&
          lon >= -180 &&
          lon <= 180;
        return checkLocation;
      }

      if (!this.rnAddressShow) {
        if (value != "" && type == "latitude") {
          if (isNaN(value) || value < -90 || value > 90) {
            return false;
          }
        }
        if (value != "" && type == "longitude") {
          if (isNaN(value) || value < -180 || value > 180) {
            return false;
          }
        }
        if (value == "" && (type == "latitude" || type == "longitude")) {
          return false;
        }
      }

      return true;
    },
    isFormValid() {
      let formValid = true;
      if (!this.disabled && this.serial == "") {
        formValid = false;
      }
      if (this.rnAddressShow) {
        formValid = this.rn_address != "" ? true : false;
      } else {
        formValid = this.rn_latitude != "" && this.rn_longitude != "";
      }
      return (
        this.operatorKey.trim() &&
        this.operator.trim() &&
        this.isValidNumber(this.azimuth) &&
        this.isValidNumber(this.height_agl) &&
        this.isValidNumber(this.height_gps) &&
        this.isValidNumber(this.downtilt) &&
        this.isValidNumber(this.rn_above_open) &&
        this.isValidNumber(this.rn_above_roof) &&
        this.isValidCoordinates(this.bn_location) &&
        this.isValidCoordinates(this.rn_location) &&
        formValid
      );
    },
    makeSVGResponsive(svg_id) {
      const svgContainer = document.getElementById(svg_id);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );

          let diagram = "";
          if (svg_id == "path-loss-svg-container") {
            diagram = "path-loss";
          } else if (svg_id == "link-speed-svg-container") {
            diagram = "link-speed";
          } else {
            diagram = "line";
          }

          // Add click event listener to the svg element
          svg.addEventListener("click", () => {
            this.enlargeImage(diagram);
          });
        }
      }
      const rowElement = this.$refs.sidebarContainer;
      if (rowElement && window.innerWidth > 767) {
        rowElement.style.height = window.innerHeight - 40 + "px";
      }
    },
    makeDialogSVGResponsive() {
      const svgId = "svg-container";
      const svgContainer = document
        .getElementById("svg-popup")
        .querySelector(`#${svgId}`);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );
        }
      }
    },
    selectOperator(val) {
      this.operator = val;
      this.operatorError = false;
      this.showOperatorModal = false;
    },
    checkAPIKeyHealth() {
      this.operatorKeyError = false;
      this.operatorDisabled = false;
      this.operator = "";
      this.keyResponse = "";
      if (this.operatorKey != "") {
        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/health/api-key";

        // Define the request data
        const requestData = {
          post_url: postUrl,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              loader.hide();
              if (response.data.detail) {
                this.keyResponse = response.data.detail;
                this.operatorKeyError = true;
              } else {
                if (response.data.operator != "Tarana") {
                  this.operatorDisabled = true;
                  this.operator = response.data.operator;
                }
                alert("Successfully Authenticated");
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = true;
      }
    },
    handleUpdateLatLng(data) {
      this.rn_latitude = data.coordinates.lat.toFixed(6);
      this.rn_longitude = data.coordinates.lng.toFixed(6);
      this.rn_address = data.address;
      //this.rn_location = this.rn_latitude + ", " + this.rn_longitude;
      this.findOperators();
    },
    findAddress() {
      if (
        this.rn_latitude != "" &&
        this.rn_longitude != "" &&
        this.operatorKey != "" &&
        !this.operatorDisabled
      ) {
        const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
        const postUrl =
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          this.rn_latitude +
          "," +
          this.rn_longitude +
          "&key=" +
          key;

        // Define the request data
        const requestData = {
          post_url: postUrl,
        };
        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              if (response.data) {
                this.rn_address = response.data.results[0].formatted_address;
                this.findOperators();
              }
            })
            .catch(() => {
              console.log("Error");
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
    findOperators() {
      this.addressError = false;
      this.addressErrorMsg = "";
      if (
        this.rn_address != "" &&
        this.operatorKey != "" &&
        !this.operatorDisabled
      ) {
        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/operators";

        // Define the request data
        const requestData = {
          post_url: postUrl + "/" + encodeURIComponent(this.rn_address),
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              loader.hide();

              if (response.data.detail) {
                this.addressErrorMsg = response.data.detail;
              } else if (response.data.operator_list) {
                let operatorList = response.data.operator_list;
                // Extract names from operatorList
                this.operatorNames = operatorList.map(
                  (operator) => operator.name
                );
                this.checkOperatorExist();
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.addressError = true;
      }
    },
    checkOperatorExist() {
      this.operatorError = false;
      if (this.operator != "" && this.operatorNames.length > 0) {
        if (!this.operatorNames.includes(this.operator)) {
          this.operatorError = true;
        }
      }
    },
    calculateLost() {
      this.serialError = false;
      this.addressError = false;
      this.successful = false;
      this.showInfoDiv = false;
      this.apiResponse = "";
      if (this.isFormValid()) {
        let jsonArray = [];
        let rnObject = {};
        if (this.rn_address != "" && this.rnAddressShow) {
          rnObject.address = this.rn_address;
        }

        let rnLocationArray = [];
        if (this.rn_location != "") {
          rnLocationArray = this.rn_location.split(", ");
          rnObject.latitude = parseFloat(rnLocationArray[0]);
          rnObject.longitude = parseFloat(rnLocationArray[1]);
        }

        if (!this.rnAddressShow) {
          rnObject.latitude =
            this.rn_latitude != "" ? parseFloat(this.rn_latitude) : "";
          rnObject.longitude =
            this.rn_longitude != "" ? parseFloat(this.rn_longitude) : "";
        }

        if (!this.disabled) {
          jsonArray["bn"] = {};
          jsonArray["bn"].operator = this.operator;
          if (this.serial != "") jsonArray["bn"].serial = this.serial;
          if (this.bn_location != "") {
            let bnLocationArray = this.bn_location.split(", ");
            jsonArray["bn"] = {
              latitude: parseFloat(bnLocationArray[0]),
              longitude: parseFloat(bnLocationArray[1]),
            };
          }
          if (this.height_agl != "")
            jsonArray["bn"].height_agl = this.height_agl;
          if (this.height_gps != "")
            jsonArray["bn"].height_gps = this.height_gps;
          if (this.azimuth != "") jsonArray["bn"].azimuth = this.azimuth;
          if (this.downtilt != "") jsonArray["bn"].downtilt = this.downtilt;
        }

        let heightsObject = {};
        if (this.rn_above_open != "")
          heightsObject.above_open = this.rn_above_open;
        if (this.rn_above_roof != "")
          heightsObject.above_roof = this.rn_above_roof;

        if (Object.keys(heightsObject).length > 0) {
          rnObject.heights = heightsObject;
        }

        jsonArray["rn_list"] = [rnObject];

        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/predict/sector";

        // Define the request data
        const jsonObject = { ...jsonArray };
        if (this.operator != "") {
          jsonObject.operator = this.operator;
        }
        const requestData = {
          post_url: postUrl,
          json_data: jsonObject,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              this.successful = true;
              this.showInfoDiv = true;
              loader.hide();

              if (response.data.detail) {
                this.apiResponse = response.data.detail;
              } else {
                if (response.data.bn) {
                  this.operator = response.data.operator;
                  this.serial = response.data.bn.serial;
                  this.baseNode.lat = response.data.bn.latitude
                    ? parseFloat(response.data.bn.latitude)
                    : "";
                  this.baseNode.lng = response.data.bn.longitude
                    ? parseFloat(response.data.bn.longitude)
                    : "";
                  if (this.baseNode.lat != "" && this.baseNode.lng != "") {
                    this.bn_location =
                      this.baseNode.lat + ", " + this.baseNode.lng;
                  }

                  this.height_agl = response.data.bn.height_agl
                    ? parseFloat(response.data.bn.height_agl)
                    : "";
                  this.height_gps = response.data.bn.height_gps
                    ? parseFloat(response.data.bn.height_gps)
                    : "";
                  this.azimuth = response.data.bn.azimuth
                    ? parseFloat(response.data.bn.azimuth)
                    : "";
                  this.downtilt = response.data.bn.downtilt
                    ? parseFloat(response.data.bn.downtilt)
                    : "";
                }

                if (response.data.rn_list.length > 0) {
                  if (response.data.rn_list[0].rn) {
                    this.remoteNode.lat = parseFloat(
                      response.data.rn_list[0].rn.latitude
                    );
                    this.remoteNode.lng = parseFloat(
                      response.data.rn_list[0].rn.longitude
                    );
                    this.rn_address = response.data.rn_list[0].rn.address;
                    this.rn_location =
                      this.remoteNode.lat + ", " + this.remoteNode.lng;

                    this.rn_latitude = this.remoteNode.lat;
                    this.rn_longitude = this.remoteNode.lng;

                    this.rn_above_open = parseFloat(
                      response.data.rn_list[0].rn.heights.above_open
                    );
                    this.rn_above_roof = parseFloat(
                      response.data.rn_list[0].rn.heights.above_roof
                    );
                  }
                  if (response.data.rn_list[0].aiml_output.path_loss) {
                    this.pathLossData =
                      response.data.rn_list[0].aiml_output.path_loss;
                    this.pathLossSvg = this.pathLossData.cdf_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("path-loss-svg-container");
                    });
                  }
                  if (response.data.rn_list[0].aiml_output.link_speed) {
                    this.linkSpeedData =
                      response.data.rn_list[0].aiml_output.link_speed;
                    this.linkSpeedSvg = this.linkSpeedData.cdf_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("link-speed-svg-container");
                    });
                  }
                  if (response.data.rn_list[0].profile_url) {
                    this.profileSvg = response.data.rn_list[0].profile_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("profile-svg-container");
                    });
                  }
                } else {
                  this.successful = false;
                  this.rn_location = "";
                  this.remoteNode.lat = "";
                  this.remoteNode.lng = "";
                  this.rn_above_open = "";
                  this.rn_above_roof = "";
                }
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = this.operatorKey != "" ? false : true;
        this.operatorError = this.operator != "" ? false : true;
        if (this.rn_address == "" && this.rnAddressShow) {
          this.addressError = true;
        }
        if (!this.disabled) {
          this.serialError = true;
        }
      }
    },
  },
};
</script>
