<template>
  <div class="map-container" ref="mapDivRef"></div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  name: "GoogleMap",
  props: {
    remoteAddress: String,
    baseNode: Object,
    remoteNode: Object,
  },
  setup(props, { emit }) {
    const mapDivRef = ref(null);
    let map = null;
    let route = null;
    let baseMarker = null;
    let remoteMarker = null;
    let infoWindow = null;

    // Define the callback function to initialize the map
    const initMap = () => {
      const options = {
        styles: [
          {
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      };

      const mapOptions = {
        center: { lat: 33.567395, lng: -97.02491 },
        zoom: 12,
        disableDefaultUI: true,
        options,
      };

      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
      }

      map = new google.maps.Map(mapDivRef.value, mapOptions);
      infoWindow = new google.maps.InfoWindow();
      // Initially update the map with the provided props
      updateMap(props.baseNode, props.remoteNode);
    };

    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;

      // Set the initMap function to the window object to ensure it's accessible globally
      window.initMap = initMap;

      // Load the necessary libraries asynchronously
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Event listener for window resize
      window.addEventListener("resize", resizeMap);
    });

    // Watch for changes in props
    watch(
      [() => props.remoteAddress, () => props.baseNode, () => props.remoteNode],
      ([remoteAddress, baseNode, remoteNode]) => {
        if (map) {
          if (remoteAddress) geocodeAddress(remoteAddress);
          updateMap(baseNode, remoteNode);
        }
      },
      { deep: true }
    );

    const geocodeAddress = (address) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          const latLng = {
            lat: location.lat(),
            lng: location.lng(),
          };
          placeAndDragMarker(latLng);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    };

    const getAddressFromLatLng = (latLng, callback) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address;
            callback(address);
          } else {
            console.log("No results found");
            callback(null);
          }
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    };

    const placeAndDragMarker = (location, bnExist = false) => {
      let rnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#c14d23",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#c14d23",
        strokeOpacity: 0.4,
      };
      // Create a Data URL from your SVG with custom background and stroke colors
      if (!bnExist) {
        const svgIcon = `
        <svg viewBox="-4 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>remote-marker</title> <desc>Created with Sketch.</desc> <defs> </defs> <g id="Vivid.JS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Vivid-Icons" transform="translate(-125.000000, -643.000000)"> <g id="Icons" transform="translate(37.000000, 169.000000)"> <g id="remote-marker" transform="translate(78.000000, 468.000000)"> <g transform="translate(10.000000, 6.000000)"> <path d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" id="Shape" fill="#c14d23"> </path> <circle id="Oval" fill="#000" fill-rule="nonzero" cx="14" cy="14" r="7"> </circle> </g> </g> </g> </g> </g> </g></svg>`;

        // Encode the SVG to a Data URL
        const encodedSVG =
          "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(svgIcon);
        rnMarkerIcon = {
          url: encodedSVG, // Use the encoded SVG Data URL
          scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
        };
      }

      if (remoteMarker) {
        remoteMarker.setPosition(location);
      } else {
        remoteMarker = new google.maps.Marker({
          position: location,
          icon: rnMarkerIcon,
          map,
          draggable: bnExist ? false : true,
          title: "RN Marker",
        });

        remoteMarker.addListener("dragend", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          updateLocationInfo(newLatLng);
        });

        remoteMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, remoteMarker);
        });
      }

      map.setCenter(location);
    };

    const updateLocationInfo = (latLng) => {
      getAddressFromLatLng(latLng, (address) => {
        emit("update-lat-lng", { coordinates: latLng, address });
      });
    };

    const showInfoWindow = (latLng, marker) => {
      getAddressFromLatLng(latLng, (address) => {
        let newLatitude = latLng.lat.toFixed(6);
        let newLongitude = latLng.lng.toFixed(6);
        const contentString = `
          <div class="info-window">
            <h3>Location Info</h3>
            <p><strong>Address:</strong> ${address || "N/A"}</p>
            <p><strong>Latitude:</strong> ${newLatitude}</p>
            <p><strong>Longitude:</strong> ${newLongitude}</p>
          </div>
        `;

        if (infoWindow) infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
      });
    };

    const updateMap = (baseNode, remoteNode) => {
      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }
      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }
      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }

      // Define coordinates for two points (replace with actual coordinates)
      const baseLatLng = new google.maps.LatLng(baseNode.lat, baseNode.lng);
      const remoteLatLng = new google.maps.LatLng(
        remoteNode.lat,
        remoteNode.lng
      );

      let baseNodeExist = false;
      let remoteNodeExist = false;

      if (baseNode.lat != "" && baseNode.lng != "") {
        // Add marker for the base node
        baseMarker = new google.maps.Marker({
          position: baseLatLng,
          map,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: "#025287",
            fillOpacity: 1,
            strokeWeight: 10,
            strokeColor: "#025287",
            strokeOpacity: 0.4,
          },
          title: "BN Marker",
        });

        baseMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, baseMarker);
        });

        map.setCenter(baseNode);
        baseNodeExist = true;
      }

      if (remoteNode.lat != "" && remoteNode.lng != "") {
        placeAndDragMarker(remoteNode, baseNodeExist);
        remoteNodeExist = true;
      }

      if (baseNodeExist && remoteNodeExist) {
        // Calculate distance between base and remote nodes
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          baseLatLng,
          remoteLatLng
        );

        // Adjust zoom level based on distance
        let zoomLevel = 12; // Default zoom level
        if (distance < 100000) {
          zoomLevel = 10; // Adjust zoom level for closer distances
        } else if (distance > 1000000) {
          zoomLevel = 8; // Adjust zoom level for larger distances
        } else if (distance > 10000000) {
          zoomLevel = 6;
        }

        // Update map zoom level
        map.setZoom(zoomLevel);

        const lineSymbol = {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 0.8,
        };

        // Draw a polyline between the two points
        route = new google.maps.Polyline({
          path: [baseLatLng, remoteLatLng],
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "3px",
            },
          ],
        });
        route.setMap(map);
      }
    };

    const resizeMap = () => {
      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
      }
    };

    return {
      mapDivRef,
    };
  },
};
</script>
